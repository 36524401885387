
h3, p {
    margin: 0;
    font-weight: 400;
}

.work-bench {
    display: flex;
    justify-content: start;
    height: 100%;
    min-width: 1400px;

    ::v-deep .el-tab-pane {
        height: 100%;
    }

    ::v-deep .el-tabs {
        flex: 1;
        width: 1%;
    }

    ::v-deep .el-tabs {
        height: 100%;
        width: 100%;
        box-shadow: none;
        border: none;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: column;
        flex-direction: column;
        background: transparent;
    }

    ::v-deep .el-tabs__header {
        border-bottom: none;
        margin: 0;
    }

    ::v-deep .el-tabs__header .is-active {
        font-size: 18px;
        font-weight: 400;
        color: #16113F;
    }

    ::v-deep .el-tabs__content {
        height: 1%;
        flex: 1;
        background: #fff;
        padding-top: 10px;
        padding-bottom: 0;
    }

    ::v-deep .el-tabs__item {
        height: 52px;
        line-height: 52px;
        font-size: 18px;
        border: none;
        color: #5F5C74;
    }

    ::v-deep .el-tabs__active-bar {
        background: #9ACC5B;
    }

    ::v-deep .el-tabs__active-bar:before {
        position: absolute;
        top: -13px;
        left: 40%;
        content: '';
        width: 0;
        height: 0;
        border-style: dashed dashed solid dashed;
        border-width: 7px;
        border-color: transparent transparent #9ACC5B transparent;
    }

    .work-bench-left {
        display: flex;
        flex-direction: column;
        width: 1%;
        flex: 1;
        height: 1%;

        .type-box {
            i {
                color: #685FEF;
            }

            span {
                margin-left: 7px;
                font-size: 20px;
                color: #16113F;
            }
        }

        .outlink-box {
            height: 108px;
            padding: 24px 0;
            display: flex;

            .left-content {
                padding: 20px 0 42px 20px;

                .top-content {
                    font-size: 20px;
                    color: #ffffff;
                }

                .bottom-content {
                    font-size: 16px;
                    color: #ffffff;
                    width: 162px;
                    margin-top: 12px;
                    opacity: 0.73;
                }
            }

            .contain-box {
                width: 20%;
                height: 100%;
                margin-right: 16px;
                border-radius: 9px;
                display: flex;
                overflow: hidden;
                cursor: pointer;
                background-repeat: no-repeat;
                background-size: 100% 100%;

                &:nth-of-type(5) {
                    margin-right: 0;
                }
            }

            .material-box {
                background-image: url("../../../assets/img/image/material-box.png");
            }

            .script-box {
                background-image: url("../../../assets/img/image/script-box.png");
            }

            .video-box {
                background-image: url("../../../assets/img/image/video-box.png");
            }

            .textPic-box {
                background-image: url("../../../assets/img/image/textPic-box.png");
            }

            .dataAnalysis-box {
                background-image: url("../../../assets/img/image/data-analysis.png");
            }
        }

        .work-task {
            display: flex;

            .task {
                height: 424px;
                background: #ffffff;
                border-radius: 10px;
                margin: 24px 0;
                padding: 10px 20px 0;
                display: flex;
                width: 70%;
                position: relative;

                .view-more {
                    position: absolute;
                    text-align: center;
                    font-size: 18px;
                    color: #5E53F0;
                    margin: 22px 0 18px 0;
                    cursor: pointer;
                    right: 20px;
                    top: 0;
                    z-index: 1;
                }
            }

            .task-right {
                width: 33%;
                height: 434px;
                background: #ffffff;
                border-radius: 10px;
                margin: 24px 0 24px 20px;
                display: flex;
                flex-direction: column;

                .show-right {
                    display: flex;
                    justify-content: space-between;
                    padding: 20px;

                    .show-name {
                        font-size: 18px;
                        color: #16113F;
                    }

                    .show-switch {
                        color: #16113F;
                        display: flex;
                        justify-content: space-between;
                        cursor: pointer;

                        .switch-icon {
                            transform: rotate(90deg);
                            margin-right: 6px;
                        }

                        .switch-text {
                            font-size: 14px;
                            color: #16113F;
                        }
                    }
                }

                .task-right-box {
                    padding: 0 15px;
                    position: relative;
                    flex: 1;

                    .task-title {
                        max-width: 260px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }

                    .icon-background {
                        img {
                            width: 27px;
                            height: 27px;
                        }
                    }

                    .rank-title {
                        position: absolute;
                        left: 20%;
                        top: 25%;
                    }
                }
            }

            .task-list {
                display: flex;
                flex-wrap: wrap;
                height: 100%;
                min-height: 360px;
                justify-content: flex-start;
                overflow: hidden;

                .list-item {
                    width: calc(33% - 10px);
                    height: 100%;
                    margin-right: 16px;

                    &:nth-of-type(3) {
                        margin-right: 0;
                    }

                    .container {
                        width: 100%;
                        border: 1px solid #E3E4EC;
                        transition: all .25s ease-out;
                        border-radius: 8px;
                        cursor: pointer;

                        .item-cover {
                            position: relative;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: 100%;
                            height: 200px;

                            img {
                                max-width: 100%;
                                max-height: 100%;
                                /*border-radius:8px 8px 0 0;*/
                            }

                            img.sign {
                                position: absolute;
                                border-radius: 0;
                                top: 25px;
                                left: 0;
                                opacity: .8;
                                width: 80px;
                            }
                        }

                        .item-content {
                            padding: 10px 0 0 16px;

                            .item-title {
                                display: block;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                color: #3A3567;
                                font-size: 16px;
                            }

                            .item-info {
                                margin-top: 10px;

                                .item-author {
                                    display: flex;
                                    justify-content: space-between;
                                    align-items: center;

                                    span {
                                        font-size: 14px;
                                        color: #A6A0C3;
                                    }

                                    .item-avatar {
                                        display: flex;
                                        align-items: center;
                                        margin: 10px 0;

                                        img {
                                            width: 24px;
                                            height: 24px;
                                            margin-right: 10px;
                                            border-radius: 50%;
                                        }

                                        span {
                                            display: block;
                                            width: 50px;
                                            overflow: hidden;
                                            text-overflow: ellipsis;
                                            white-space: nowrap;
                                        }
                                    }
                                }

                                .item-evaluate {
                                    display: flex;
                                    justify-content: space-between;
                                    align-items: center;
                                    font-size: 14px;
                                    margin-bottom: 10px;
                                    color: #3A3567;

                                    i {
                                        color: #F19149;
                                        margin-right: 2px;
                                    }

                                    .evaluate-right {
                                        background: #564CED;
                                        color: #ffffff;
                                        width: 141px;
                                        height: 32px;
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        font-size: 14px;
                                        position: relative;
                                    }

                                    .evaluate-right:before {
                                        position: absolute;
                                        top: 0;
                                        left: 0;
                                        content: '';
                                        width: 0;
                                        height: 0;
                                        border: 16px solid;
                                        border-color: transparent transparent transparent #ffffff;
                                    }
                                }
                            }
                        }

                        .item-status {
                            text-align: center;
                            padding: 8px;
                            border-top: 1px solid #F8F7FF;

                            .view-task {
                                color: #2338E6;
                            }

                            .finish-task {
                                color: #20C997;
                            }

                            .time-out-task {
                                color: #666666;
                            }
                        }
                    }
                }
            }

            .no-task-list {
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }

        .left-item {
            margin: 20px 0 0;
            height: 705px;
            display: flex;
            justify-content: start;
        }

        .task-list {
            padding: 0;
            max-height: 150px;
            overflow: auto;

            .task-item {
                display: flex;
                padding: 15px 15px 0;
                align-items: center;
                border-bottom: 1px solid #eaeaea;
                flex-wrap: wrap;

                &:last-of-type {
                    border-bottom: none;
                }

                .img {
                    width: 44px;
                    height: 44px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-bottom: 15px;

                    img {
                        max-width: 100%;
                        max-height: 100%
                    }
                }

                .detail {
                    width: 1%;
                    flex: 1;
                    line-height: 1;
                    margin: 0 10px 15px 10px;
                    min-width: 200px;

                    .text {
                        font-size: 14px;
                        width: 100%;
                        height: 20px;

                        .title {
                            display: block;
                            max-width: calc(100% - 31px);
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            float: left;
                            color: #333;
                            line-height: 1;
                        }
                    }

                    .second {
                        font-size: 12px;
                        color: #999;
                        line-height: 1.5;

                        span {
                            display: inline-block;
                            margin-right: 5px;
                        }
                    }
                }
            }

        }

        .oper {
            margin-bottom: 15px;

            .btn-red {
                cursor: pointer;
            }
        }
    }

    .current {
        color: #20C997;
    }

    .table-content {
        height: 100%;
        width: calc(100% - 40px);
        margin-right: 20px;
        background: #ffffff;
        border-radius: 10px;

        .table-box {
            padding: 20px;
            overflow: hidden;

            .icon-background {
                img {
                    width: 27px;
                    height: 27px;
                }
            }

            .rank-title {
                position: absolute;
                left: 20%;
                top: 32%;
            }

            .showing {
                display: flex;
                align-items: center;
                /*width: 30px;*/
                height: 58px;

                img {
                    display: inline-block;
                    height: 46px;
                    width: 46px;
                    margin-right: 10px;
                    /*border-radius: 50%;*/
                }

                .showing-name {
                    max-width: 260px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }
        }

        &:nth-of-type(3) {
            margin-right: 0;
        }

        .view-more {
            text-align: center;
            font-size: 18px;
            color: #5E53F0;
            margin: 22px 0 18px 0;
            cursor: pointer;
        }

        .icon-background {
            img {
                width: 27px;
                height: 27px;
            }
        }

        .rank-title {
            position: absolute;
            left: 48%;
            top: 25%;
        }
    }

    .clearfix {
        zoom: 1;
    }

    a {
        color: #333;
    }

    .rank-table {
        ::v-deep .is-scrolling-none {
            height: calc(100% - 48px);
        }

        .header-event {
            cursor: pointer;

            .header-icon {
                margin-left: 6px;
                font-size: 12px;
                color: #A0A0A0;
            }

            &.current {
                color: #000;

                .header-icon {
                    color: #1E33E3;
                }
            }
        }

        .select {
            &.current {
                span {
                    font-weight: 700;
                    color: #333;
                }
            }
        }

        ::v-deep .cell {

            .cell-data {
                padding: 0 4px;
                color: #999;
                background: #eef2f9;
                border-radius: 4px;
                font-size: 14px;
                margin: 0 5px;

                i {
                    margin-right: 3px;
                }

                &.current {
                    color: #ff4c5b;
                    background-color: #fff0f1;
                }
            }
        }

        ::v-deep .handle-btn {
            span {
                width: 28px;
                height: 28px;
                display: inline-block;
                border-radius: 50%;
                line-height: 28px;

                i {
                    color: #fff;
                }
            }
        }

        ::v-deep .data-btn {
            span {
                background-color: #465CF1;
            }
        }

        ::v-deep .user-btn {
            span {
                background-color: #42D7AB;
            }
        }

        ::v-deep .play-btn {
            span {
                background-color: #FF0707;
            }
        }

        ::v-deep .chats-btn {
            span {
                background-color: #FFB624;
            }
        }

        .header-title {
            cursor: pointer;

            i {
                font-size: 12px;
                margin-left: 5px;
            }
        }
    }
}
